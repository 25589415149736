<template>
  <div :class="brandClasses.wrapper">
    <base-button :class="brandClasses.backButton" @click="signIn">
      <vf-icon name="chevron" size="md" dir="left" />
      <span class="text-sm">{{ $t.backToSignIn }}</span>
    </base-button>
    <form-forgot-password />
  </div>
</template>

<script lang="ts" setup>
export type Props = { disableModal?: boolean }
export type Emits = { signIn: [] }

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { brandClasses } = useAppConfig().components.dialog.forgotPassword
const { ModalSignIn } = useDialogsStore()

const signIn = () => {
  emit('signIn')

  if (!props.disableModal)
    ModalSignIn.open()
}
</script>
